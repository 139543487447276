import React from 'react'
import { motion } from 'framer-motion'
import Layout from '../components/Layout'
import PageBanner from '../components/PageBanner'
import useSexualAssaultPraticeAreas from '../hooks/use-sexualassault-practiceareas'
import { Container, Grid, PagePadding } from '../styles'
import PracticeAreaBlock from '../components/PracticeAreaBlock'

const PracticeBlockPageStyles = {
    marginBottom: "24px"
}

const transition = {
    duration: 0.25,
    ease: [0.43, 0.13, 0.23, 0.96]
}

const container = {
    hidden: { opacity: 0, y: 20 },
    show: {
        opacity: 1,
        y: 0,
        transition: {
            staggerChildren: 0.20,
            ...transition
        }
    }
}

const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
}

const PracticeAreasPage = () => {
    const practiceAreas = useSexualAssaultPraticeAreas();

    return (
        <Layout>
            <PageBanner title="California Sexual Assault Lawyer" subtitle="California Sexual Assault Lawyer" />
            <Container>
                <PagePadding>
                    <Grid threeCol
                       variants={container}
                       initial="hidden"
                       animate="show"
                    >
                        {practiceAreas.map(practice => {
                            return <motion.div variants={item} key={practice.slug}>
                                <PracticeAreaBlock
                                    practiceUrl={practice.slug}
                                    title={practice.title}
                                    imageSrc={practice.featuredImage.sharp.fluid}
                                    styles={{...PracticeBlockPageStyles}}
                                />
                            </motion.div>
                        })}
                    </Grid>
                </PagePadding>
            </Container>
        </Layout>
    )
}

export default PracticeAreasPage
